import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_KycRejectCommentModal = _resolveComponent("KycRejectCommentModal")!
  const _component_CommentModal = _resolveComponent("CommentModal")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createTextVNode("Запросы KYC")
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_select, {
              modelValue: $data.status,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => (($data.status) = $event)),
                _cache[1] || (_cache[1] = ($event: any) => ($options.changeStatus()))
              ],
              label: "Статус заявки",
              items: $data.statuses,
              density: "comfortable",
              "item-title": "label",
              "item-value": "key"
            }, null, 8, ["modelValue", "items"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table_server, {
      "items-per-page": $data.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[2] || (_cache[2] = ($event: any) => (($data.itemsPerPage) = $event)),
      headers: $data.headers,
      items: $data.kycRequests,
      loading: $data.loading,
      search: $data.search,
      "items-length": $data.totalElements,
      "no-data-text": 'Нет данных',
      "items-per-page-text": 'Размер страницы',
      "items-per-page-options": $data.itemsPerPageOptions,
      "item-value": "name",
      class: "elevation-1",
      "onUpdate:options": $options.load
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(item.columns.userId), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.step == 'PASSED_ONCE'? "🟩" : item.columns.step == 'REJECTED_ONCE' ? "🟥" : "🟨"), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.lastModifiedAt), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.passportFirstName), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.passportLastName), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.hasNoMiddleName ? "-" : item.columns.passportMiddleName), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.status), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.dateOfBirth), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.gender), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.citizenship), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.passportNumber), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.passportIssuedBy), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.passportIssueDate), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.passportIssuerCode), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.placeOfBirth), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.registrationAddress), 1),
          _createElementVNode("td", null, [
            _createVNode(_component_v_btn, {
              onClick: ($event: any) => ($options.getComment(item.selectable)),
              variant: "text"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Посмотреть")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_v_btn, {
              onClick: ($event: any) => ($options.downloadPassport(item.columns)),
              variant: "text"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Скачать")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _createElementVNode("td", null, [
            (this.status != 'PASSED' && this.status != 'FAILED')
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  onClick: ($event: any) => ($options.approve(item.columns)),
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Подтвердить")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (this.status != 'PASSED' && this.status != 'FAILED')
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 1,
                  onClick: ($event: any) => ($options.decline(item.selectable)),
                  color: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Отклонить")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["items-per-page", "headers", "items", "loading", "search", "items-length", "items-per-page-options", "onUpdate:options"]),
    _createVNode(_component_KycRejectCommentModal, {
      value: $data.showModal,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($data.showModal) = $event)),
      kycRequest: $data.selectedRequest,
      "onUpdate:kycRequest": _cache[4] || (_cache[4] = ($event: any) => (($data.selectedRequest) = $event)),
      tmpComment: $data.selectedComment,
      "onUpdate:tmpComment": _cache[5] || (_cache[5] = ($event: any) => (($data.selectedComment) = $event)),
      onClose: _cache[6] || (_cache[6] = ($event: any) => ($options.declineClosed())),
      onSuccess: _cache[7] || (_cache[7] = ($event: any) => ($options.declinedSuccessfully()))
    }, null, 8, ["value", "kycRequest", "tmpComment"]),
    _createVNode(_component_CommentModal, {
      comment: $data.selectedComment,
      "onUpdate:comment": _cache[8] || (_cache[8] = ($event: any) => (($data.selectedComment) = $event)),
      kycRequest: $data.selectedRequest,
      "onUpdate:kycRequest": _cache[9] || (_cache[9] = ($event: any) => (($data.selectedRequest) = $event)),
      value: $data.showComment,
      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($data.showComment) = $event)),
      onClose: _cache[11] || (_cache[11] = ($event: any) => ($options.declineComment()))
    }, null, 8, ["comment", "kycRequest", "value"]),
    _createVNode(_component_v_snackbar, {
      modelValue: $data.snackbar,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($data.snackbar) = $event)),
      timeout: $data.timeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "blue",
          variant: "text",
          onClick: _cache[12] || (_cache[12] = ($event: any) => ($data.snackbar = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Закрыть ")
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($data.snackbarText) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}