
import OfferingBidsTable from "@/components/OfferingBidsTable.vue";
export default {
  name: "OfferingBids",
  components: {OfferingBidsTable},
  mounted() {
    this.axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/offerings`)
        .then(res => {
          this.offeringsLoading = false;
          this.offerings = res.data

          if (localStorage.bidsOfferingId) {
            this.selectedOfferingId = localStorage.bidsOfferingId;
          }
        })
        .catch(err => {
          console.error(err);
          this.offeringsLoading = false;
          this.offerings = [];
        });
  },
  data() {
    return {
      selectedOfferingId: null,
      showDeclinedBids: false,
      offeringsLoading: true,
      offerings: [],
    }
  },
  watch: {
    selectedOfferingId(newOfferingId) {
      localStorage.setItem("bidsOfferingId", newOfferingId);
    }
  },
}
