import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_text_field, {
      modelValue: $data.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.search) = $event)),
      "append-icon": "mdi-magnify",
      label: "Поиск",
      "single-line": true,
      "hide-details": ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_data_table_server, {
      "onClick:row": $options.openAccountView,
      "onSelect:row": $options.openAccountView,
      "items-per-page": $data.itemsPerPage,
      "onUpdate:itemsPerPage": _cache[1] || (_cache[1] = ($event: any) => (($data.itemsPerPage) = $event)),
      headers: $data.headers,
      items: $data.accounts,
      loading: $data.loading,
      search: $data.search,
      "items-length": $data.totalElements,
      "no-data-text": 'Нет данных',
      "items-per-page-text": 'Размер страницы',
      "items-per-page-options": $data.itemsPerPageOptions,
      "item-value": "name",
      class: "elevation-1",
      "onUpdate:options": $options.loadItems
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, _toDisplayString(item.columns.id), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.lastName), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.firstName), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.middleName), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.kycPassed ? "✅" : "❌"), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.dossierPassed ? "✅" : "❌"), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.qualified == 'QUALIFIED' ? "✅" : "❌"), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.businessStatus == "ENTREPRENEUR" ? "ИП" : item.columns.businessStatus == "LEGAL_ENTITY" ? "ЮЛ" : "Физ"), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.email), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.phone), 1),
          _createElementVNode("td", null, _toDisplayString(item.columns.uid), 1),
          _createElementVNode("td", null, [
            _createVNode(_component_v_btn, {
              color: "blue-darken-2",
              onClick: ($event: any) => ($options.openAccountView(item.columns.page, item.columns.uid))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Открыть карточку")
              ]),
              _: 2
            }, 1032, ["onClick"]),
            (!item.qualified)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  color: "blue-darken-4",
                  onClick: ($event: any) => ($options.qualify(item.columns.uid))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Квалифицировать")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onClick:row", "onSelect:row", "items-per-page", "headers", "items", "loading", "search", "items-length", "items-per-page-options", "onUpdate:options"]),
    _createVNode(_component_v_snackbar, {
      modelValue: $data.snackbar,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.snackbar) = $event)),
      timeout: $data.snackbarTimeout
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "blue",
          variant: "text",
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.snackbar = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Закрыть ")
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($data.snackbarMessage) + " ", 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 64))
}