import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(" Вывод средств ")
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_combobox, {
            modelValue: $data.status,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.status) = $event)),
            label: "Статус исполнения",
            items: $data.statuses,
            density: "comfortable"
          }, null, 8, ["modelValue", "items"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table_server, {
        headers: $data.headers,
        items: $data.invoices,
        loading: $data.loading,
        search: $data.search,
        "items-length": $data.totalElements,
        "items-per-page": $data.itemsPerPage,
        "no-data-text": 'Нет данных',
        "items-per-page-text": 'Размер страницы',
        "items-per-page-options": $data.itemsPerPageOptions,
        "item-value": "name",
        class: "elevation-1",
        "onUpdate:options": $options.loadItems
      }, {
        item: _withCtx(({ item }) => [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, _toDisplayString(item.columns.accountId), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.fio), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.deposit), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.value), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.payeesAccount), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.bic), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.payeesBank), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.correspondentAccount), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.inn), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.kpp), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.status), 1),
            _createElementVNode("td", null, _toDisplayString(item.columns.createdAt), 1),
            _createElementVNode("td", null, [
              (item.columns.status === 'PROCESSING')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => ($options.approve(item.columns))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Подтвердить")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      onClick: ($event: any) => ($options.decline(item.columns))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Отклонить")
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["headers", "items", "loading", "search", "items-length", "items-per-page", "items-per-page-options", "onUpdate:options"])
    ]),
    _: 1
  }))
}