
import TransactionModal from "./TransactionModal.vue";
import { debounce } from "lodash";

export default {
  components: { TransactionModal },
  name: "MoneyTransactions",
  data() {
    return {
      transactions: [],
      accountsData: [],
      status: "IN_PROGRESS",
      statuses: ["IN_PROGRESS", "CONFIRMED", "DECLINED", "ALL"],
      txType: "ALL",
      txTypes: ["DEPOSIT", "WITHDRAW", "ALL"],
      deposit: "ALL",
      deposits: ["PRIMARY", "SECONDARY", "ALL"],
      search: null,
      itemsPerPage: 500,
      itemsPerPageOptions: [
        { value: 10, title: "100" },
        { value: 25, title: "250" },
        { value: 50, title: "500" },
        { value: 100, title: "1000" },
      ],
      headers: [
        {
          title: "ID",
          key: "humanReadableId",
          value: "humanReadableId",
          align: "left",
          sortable: false,
        },
        {
          title: "ID аккаунта",
          key: "internalId",
          value: "internalId",
          align: "left",
          sortable: true,
        },
        {
          title: "ФИО",
          key: "fio",
          value: "fio",
          align: "left",
          sortable: true,
        },
        {
          title: "Тип транзакции",
          key: "type",
          value: "type",
          align: "left",
          sortable: false,
        },
        {
          title: "Сумма",
          key: "value",
          value: "value",
          align: "left",
          sortable: true,
        },
        {
          title: "Вид счёта",
          key: "deposit",
          value: "deposit",
          align: "left",
          sortable: false,
        },
        {
          title: "Статус",
          key: "status",
          value: "status",
          align: "left",
          sortable: false,
        },
        {
          title: "Дата транзакции",
          key: "createdAtTs",
          value: "createdAtTs",
          align: "left",
          sortable: false,
        },
        { title: "", key: "uid", value: "uid", align: "left", sortable: false },
      ],
      totalElements: 0,
      currentPage: 1,
      loading: true,
    };
  },
  watch: {
    status() {
      this.loadItems();
    },
    txType() {
      this.loadItems();
    },
    deposit() {
      this.loadItems();
    },
  },
  methods: {
    loadItems(req) {
      debounce(() => {
        this.currentPage = req ? Math.max(req.page, 1) : this.currentPage;
        const size = this.itemsPerPage;
        this.axios
          .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/accounting/money`, {
            params: {
              page: this.currentPage,
              size,
              type: this.txType === "ALL" ? null : this.txType,
              status: this.status === "ALL" ? null : this.status,
              deposit: this.deposit === "ALL" ? null : this.deposit,
              search: this.search,
            },
          })
          .then((res) => {
            this.loading = false;
            console.log(res.data);
            this.totalElements = res.data.count;
            this.transactions = res.data.table.map((tx) => {
              return {
                humanReadableId: tx.humanReadableId,
                internalId: tx.internalId,
                fio: (tx.lastName +
                  " " +
                  tx.firstName +
                  " " +
                  (tx.middleName || "")) as string,
                type: tx.type,
                value: tx.value,
                deposit: tx.deposit,
                status: tx.status,
                uid: tx.uid,
                createdAtTs: tx.createdAtTs,
              };
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }, 500)();
    },
    approve(tx) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/accounting/money/${tx.uid}/status?status=CONFIRMED`
        )
        .then((_) => {
          this.loadItems({ page: this.currentPage });
        });
    },
    decline(tx) {
      this.axios
        .patch(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/accounting/money/${tx.uid}/status?status=DECLINED`
        )
        .then((_) => {
          this.loadItems({ page: this.currentPage });
        });
    },
  },
};
